<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-form-model layout="inline" @keyup.enter.native="query">
        <a-form-model-item>
          <a-input v-model="form.name" placeholder="名称" />
        </a-form-model-item>
        <a-form-model-item>
          <a-radio-group v-model="form.type">
            <a-radio
              v-for="item in menuTypeList"
              :key="item.uuid"
              :value="item.value"
            >
              {{ item.name }}
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item prop="status">
          <a-radio-group v-model="form.status">
            <a-radio
              v-for="item in statusList"
              :key="item.uuid"
              :value="item.value"
            >
              {{ item.name }}
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-space style="margin-top: 4px">
          <a-button type="primary" @click="query">查询</a-button>
          <a-button @click="reset">重置</a-button>
        </a-space>

        <private-button
          class="float-right"
          path="add"
          type="primary"
          size="default"
        >
          <span>新建</span>
        </private-button>
      </a-form-model>

      <Padding />

      <a-table
        bordered
        :dataSource="list"
        :loading="loading"
        :pagination="false"
      >
        <a-table-column title="名称" data-index="name"></a-table-column>
        <a-table-column title="图标">
          <template slot-scope="text">
            <div v-if="text.icon">
              <a-icon :type="text.icon" />
            </div>
          </template>
        </a-table-column>
        <a-table-column title="路径" data-index="path"></a-table-column>
        <a-table-column title="关联权限" data-index="permission">
          <template slot-scope="permission">
            {{ permissionRender(permission) }}
          </template>
        </a-table-column>
        <a-table-column title="类型" align="center">
          <template slot-scope="text">
            <DataDictFinder dictType="menu-type" :dictValue="text.type" />
          </template>
        </a-table-column>
        <a-table-column title="状态" align="center">
          <template slot-scope="text">
            <DataDictFinder
              dictType="status"
              :dictValue="String(text.status)"
            />
          </template>
        </a-table-column>
        <a-table-column
          title="排序"
          data-index="sort"
          align="center"
        ></a-table-column>

        <a-table-column title="描述" data-index="remarks"></a-table-column>
        <!-- <a-table-column
          title="创建者"
          data-index="createUserName"
          align="center"
        ></a-table-column>
        <a-table-column
          title="创建时间"
          data-index="createAt"
          align="center"
        ></a-table-column> -->
        <a-table-column title="操作" align="center">
          <template slot-scope="text">
            <private-button
              path="edit"
              routeType="method"
              @clickButton="edit(text)"
            >
              <span>编辑</span>
            </private-button>

            <private-button
              path="delete"
              routeType="method"
              danger
              @clickButton="deleteText(text)"
            >
              <span>删除</span>
            </private-button>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { fetchList as fetchPermissionList } from "@/api/setting/permission";
import { fetchList, remove } from "@/api/setting/menu";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      form: {},

      permissionList: [],

      loading: false,
      list: [],
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    statusList() {
      return this.findDataDict("status");
    },
    menuTypeList() {
      return this.findDataDict("menu-type");
    },
  },

  mounted() {
    fetchPermissionList({
      pageNum: 1,
      pageSize: 1000,
    }).then((res) => {
      if (Array.isArray(res.list)) {
        this.permissionList = res.list;
      }
    });

    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;
      fetchList()
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res)) {
            this.list = this.filterMenu(
              res.map((item) => {
                item.key = item.uuid;
                return item;
              }),
              "0"
            );
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    filterMenu(list, pid) {
      let tree = [];

      list.forEach((item) => {
        if (item.parentUuid === pid) {
          const children = this.filterMenu(list, item.uuid);

          const params = {
            ...item,
          };

          if (children && children.length > 0) {
            params.children = children;
          }

          tree.push(params);
        }
      });

      return tree;
    },

    edit(text) {
      window.localStorage.setItem(
        "editingMenu",
        JSON.stringify({
          ...text,
          children: undefined,
        })
      );

      let url = `${this.$route.path}/edit?status=edit`;
      this.$router.push(url);
    },

    deleteText(text) {
      const that = this;

      this.$confirm({
        title: "确认要删除这个菜单吗？",
        onOk() {
          remove({
            uuid: text.uuid,
          }).then(() => {
            that.getList();
          });
        },
      });
    },

    query() {
      this.getList();
    },
    reset() {
      this.form = {};
      this.getList();
    },

    permissionRender(permission) {
      const res = this.permissionList.find((item) => item.code === permission);
      if (res) {
        return res.name;
      } else {
        return "--";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.plus {
  margin-bottom: 16px;
}
</style>
